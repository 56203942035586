<template>
  <section>
    <div v-if="visible" class="img__background" @click="closeImage"></div>
    <transition name="image">
      <div v-if="visible" class="img">
        <img src="@/assets/img/sertificate.svg" alt="sertificate.svg" />
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  emits: ["close"],
  props: ["visible"],
  methods: {
    closeImage() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.img__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 5;
  
}

.img {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  transition: all 0.5s linear;
}

img {
  width: 60em;
  height: 70em;
}

.image-enter-active {
  animation: show 0.5s linear;
}

.image-leave-active {
  animation: show 0.5s linear reverse;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  img {
    width: 20em;
    height: auto;
  }
}
</style>
